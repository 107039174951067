/* eslint-disable no-template-curly-in-string */
import React from "react";
import { Form, Input } from "antd";
import CtaButton from "../../components/CtaButton";


const validateMessages = {
  required: "Este necesar sa completati campul ${label}.",
  types: {
    email:
      "Este necesar sa completati campul ${label} cu o adresa de email valida. Eg. contact@webhustler.ro",
    number: "Campul ${label} trebuie sa contina doar numere. Eg. 0725 458 994",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
    default: "Campul ${label} trebuie sa contina doar numere. Eg. 0725 458 994",
  },
  string: {
    min: "Acest camp contine mai putine caractere decat este necesar. Sigur nu ai uitat ceva?",
  },
};
const SecondaryForm = ({ onFinish, data }) => {

  
  return (
    <Form
      layout="vertical"
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="adress"
        label="Localitatea și județul în care va desfășurați activitatea"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="sales_category"
        label="Ce produse sau servicii vindeți în magazinul dvs?"
        placeholder="produse alimentare, patiserie, cafea, haine etc"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="cheapestProduct"
        label="Cât costă cel mai ieftin produs din magazinul tău 
  vândut în mod uzual?"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mostExpensive" label="Dar cel mai scump?">
        <Input />
      </Form.Item>
      <CtaButton htmlType="submit" type="primary">
        {data.ctaText}
      </CtaButton>
    </Form>
  );
};

export default SecondaryForm;
