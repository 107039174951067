import React from "react";
import { Form, Input, Checkbox } from "antd";
import CtaButton from "../../components/CtaButton";
import { Link } from "react-router-dom";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "Este necesar sa completati campul ${label}.",
  types: {
    email:
      "Este necesar sa completa/ti campul ${label} cu o adresa de email valida. Eg. contact@webhustler.ro",
    number: "Campul ${label} trebuie sa contina doar numere. Eg. 0725 458 994",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
    default: "Campul ${label} trebuie sa contina doar numere. Eg. 0725 458 994",
  },
  string: {
    min: "Acest camp contine mai putine caractere decat este necesar. Sigur nu ai uitat ceva?",
  },
};

const MainForm = ({ onFinish, data }) => {
  return (
    <Form
      layout="vertical"
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="fullName"
        label="Nume și prenume complet"
        rules={[
          {
            required: true,
            type: "string",
            min: 5,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        rules={[
          {
            required: true,
          },
        ]}
        label="În calitate de"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="companyName"
        rules={[
          {
            required: true,
            type: "string",
            min: 3,
          },
        ]}
        label="Denumire firmă"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            type: "string",
            min: 6,
          },
        ]}
        name="phone"
        label="Telefon"
      >
        <Input />
      </Form.Item>
      <Form.Item
        valuePropName="checked"

        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Este necesar sa fiti de acord cu termenii si conditiile de utilizare a serviciului.')),
          },
        ]}
        name="termsBox"
        label="Termeni si conditii"
      >
        <Checkbox>
          Sunt de acord cu<Link to="terms-and-conditions">termenii și condițiile de utilizare a serviciului.</Link>
        </Checkbox>
      </Form.Item>
      <CtaButton htmlType="submit" type="primary">
        {data.ctaText}
      </CtaButton>
    </Form>
  );
};
export default MainForm;
