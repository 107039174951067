import React, { useState, useEffect } from "react";
import { Card, Row, Col, Space } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import CtaButton from "./components/CtaButton";
import PageContent from "./PageContent";
import PageIntro from "./components/PageIntro";
import Packages from "./pages/Packages";
import PageForm from "./pages/PageForm";
import { SLIDER, FORM, PACKAGES, RESULTS } from "./page.types";
import PAGES_DATA from "./content.constants";
import { Link } from "react-router-dom";
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
const PageContentWithRef = React.forwardRef((props, ref) => (
  <PageContent ref={ref} {...props} />
));

export default function ContentWrapper({ data, currentPage, setCurrentPage }) {
  const { pageType, showCta } = data;
  const carouselRef = React.useRef();
  const contentRef = React.useRef();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  let navigate = useNavigate();
  const isFirstPage = currentPage === 0;

  const [textSecondBtn, setTextSecondBtn] = React.useState("Sus");
  let location = useLocation();
  React.useEffect(() => {
    const pageIndex = PAGES_DATA.findIndex(
      (el) => el.path === location.pathname
    );

    if (pageIndex !== -1) {
      setCurrentPage(pageIndex);
    }
  }, [location, setCurrentPage]);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setTextSecondBtn(isFirstPage ? "Sus" : "Inapoi");
    }

    return function cleanUp() {
      isMounted = false;
    };
  }, [isFirstPage]);

  const beforeChangeSlide = (currentEl) => {
    if (isMobileVersion) {
      return;
    }
    let nextSlideIndex = 0;

    if (currentEl === 0 && currentSlide === 3) {
      setCurrentSlide(nextSlideIndex);

      return;
    }
    nextSlideIndex = currentEl === data.content.length - 1 ? 0 : currentEl + 1;
    setCurrentSlide(nextSlideIndex);
  };

  const size = useWindowSize();

  const isMobileVersion = size.width < 768;
  const {
    ctaText,
    content,
    descriptionP1,
    descriptionP2,
    descriptionP3,
    descriptionP4,
    title,
    introImage,
  } = data;
  const pageIntroProps = {
    contentRef,
    descriptionP1,
    descriptionP2,
    title,
    isMobileVersion,
    introImage,
    descriptionP3,
    descriptionP4,
  };

  const changePage = () => {
    if (PAGES_DATA[currentPage + 1] && PAGES_DATA[currentPage + 1]?.path) {
      if (currentPage === 8) {
        // window.location.replace(PAGES_DATA[currentPage + 1].path);
      } else {
      }
      navigate(PAGES_DATA[currentPage + 1].path, {
        replace: currentPage === 8,
      });
      if (currentPage === 8) {
        console.log("sterg starea");
        const { history } = window;
        history.pushState(null, "/", window.location.href);
        history.back();
        history.forward();
        window.onpopstate = function () {
          window.history.go(1);
        };
      }
      setCurrentPage(currentPage + 1);
    }
  };
  const goTo = () => {
    if (data.pageType === SLIDER) {
      if (scrollSlideManually("next")) {
        return;
      }
      setCurrentSlide(0);
      changePage();
    } else {
      changePage();
    }
  };
  const getNextSlide = (direction, curEl) => {
    if (direction === "next") {
      if (curEl === 2) {
        return false;
      }
      return curEl + 1;
    } else {
      if (curEl === 0) {
        return false;
      }
      return curEl - 1;
    }
  };
  const scrollSlideManually = (direction) => {
    if (!isMobileVersion || !content) {
      return false;
    }
    const nextSlide = getNextSlide(direction, currentSlide);

    carouselRef.current.next();
    if (!nextSlide) {
      return false;
    }
    setCurrentSlide(nextSlide);
    return true;
  };

  const goOnePageBack = () => {
    setCurrentSlide(0);
    navigate(-1);
    setCurrentPage(currentPage - 1);
  };

  const goBack = () => {
    if (isFirstPage) {
      window.scrollTo(0, 0);
      return;
    }
    if (data.pageType === SLIDER && isMobileVersion) {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      } else {
        goOnePageBack();
      }
      return;
    }
    goOnePageBack();
  };

  const MainContentComponent = {
    [SLIDER]: (
      <PageContentWithRef
        ref={contentRef}
        beforeChangeSlide={beforeChangeSlide}
        carouselRef={carouselRef}
        data={content}
        currentSlide={currentSlide}
        isMobileVersion={isMobileVersion}
        currentPage={currentPage}
      />
    ),
    [PACKAGES]: <Packages goTo={goTo} data={data} />,
    [FORM]: <PageForm data={data} goTo={goTo} />,
    [RESULTS]: <></>,
  };

  return (
    <div style={{display: 'flex',flexDirection:'column'}}>
        <Card
          // style={{ margin: "1rem" }}
          bodyStyle={{ padding: 0, marginLeft: 10,
            marginRight: 10}}
          className="content-wrapper"
          bordered={false}
        >
          <div className="content-wrapper-inner border-primary">
            <PageIntro {...pageIntroProps} />

            {MainContentComponent[pageType]}
            {showCta && (
              <Space className="cta-buttons-container" direction="vertical">
                <CtaButton type="primary" onClick={goTo}>
                  {isMobileVersion && currentSlide < 2 ? "Vezi urmatorul beneficiu" : ctaText}
                </CtaButton>
                <CtaButton type="default" onClick={goBack}>
                  {textSecondBtn}
                </CtaButton>
              </Space>
            )}
          </div>
        </Card>
        <div className="footer-wrapper">
          <Link to="/termeni-si-conditii">Termeni si conditii</Link>
          <Link to="/politica-de-confidentialitate">
            Politica de confidentialitate
          </Link>
        </div>
        </div>
  );
}
