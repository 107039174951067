import { Divider, Typography } from "antd";
import CtaButton from "./CtaButton";
const { Title, Paragraph } = Typography;

function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}
const RenderTitle = ({ title }) => (
  <div className="title-wrapper s-mb">
    <Title level={3}>{title} </Title>
  </div>
);

const PageIntro = (props) => {
  const {
    descriptionP1 = "",
    descriptionP2 = "",
    descriptionP3 = "",
    title,
    descriptionP4,
  } = props;

  const isValidDescription = (description) =>
    isString(description) && description.length > 3;

  if (!isValidDescription(descriptionP1)) {
    return <RenderTitle title={title} />;
  }

  const renderDescription = (description) => {
    if (isValidDescription(description)) {
      return <Paragraph>{description} </Paragraph>;
    }
    return null;
  }
  const goTo = () => {

    window.scrollTo(0, document.body.scrollHeight);
  }
  const bullets = [descriptionP1, descriptionP2, descriptionP3, descriptionP4];
  return (
    <div>
      <div
        style={{
          maxWidth: 500,
          textAlign: "left",
        }}
      >
        <RenderTitle title={title} />
        <ul>
          {bullets.map((description) => {
            if(!description) return null;
            return (
              <li>
                {renderDescription(description)}
              </li>
            );  
          })}
        </ul>

        {title !== "Cere oferta" && (
          
          <CtaButton size="small" type="primary" onClick={goTo}>
            Descopera mai multe
          </CtaButton>
        )}

      </div>
      <Divider />
    </div>
  );
};

export default PageIntro;
