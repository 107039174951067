import React from "react";
import { Card, Typography, Button, Space, Row, Col } from "antd";

const { Title, Paragraph } = Typography;

const pageText = {
  p1: "*Prețurile nu includ TVA. ",
  p2:
    "Prețurile abonamentelor de mai sus sunt estimative. Apăsați butonul cere oferta pentru a va putea trimite o oferta specială pentru afacerea dvs.",
};

const packageBodyStyle = {
  backgroundColor: "#E5E5E5",
  borderRadius: "0.5rem",
};

const packageHeadStyle = {
  fontWeight: 700,
};

const Packages = ({ data, goTo }) => {

  if (!data) {
    return null;
  }

  return (
    <Space direction="vertical" className="packages-wrapper">
      <Row gutter={[16, 16]} align="center" justify="middle">
        {data.content.map((pack) => {
          return (
            <Col>
              <Space direction="vertical" size={"large"}>
                <Card
                  bordered={false}
                  className="package-container"
                  headStyle={packageHeadStyle}
                  bodyStyle={packageBodyStyle}
                  title={pack.name}
                >
                  <Row gutter={[8, 16]}>
                    <Col span={14}>
                      <Paragraph level={5}>Clienți unici</Paragraph>
                    </Col>
                    <Col span={10}>
                      <Title level={5}>{pack.noClients}</Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Paragraph level={5}>Tranzacții</Paragraph>
                    </Col>
                    <Col span={12}>
                      <Title level={5}>{pack.noTransactions}</Title>
                    </Col>
                  </Row>

                  <Row className="s-mt">
                    <Col push={8} span={24}>
                      <Title level={5}>
                        {pack.price} {pack.currency}*
                      </Title>
                      <Paragraph level={5}>pe lună</Paragraph>
                    </Col>
                  </Row>
                </Card>
                <Button onClick={goTo} type="primary">
                  {data.ctaText.toUpperCase()}
                </Button>
              </Space>
            </Col>
          );
        })}
      </Row>
      <div className="s-mt">
        <Paragraph className="packages-text-wrapper">
          {pageText.p1}
          <br />
          {pageText.p2}
        </Paragraph>
      </div>
    </Space>
  );
};

export default Packages;
