import React from "react";

import DragDropList from "./Forms/OrderListForm";
import MainForm from "./Forms/MainForm";

import SecondaryForm from "./Forms/SecondaryForm";
/* eslint-disable no-template-curly-in-string */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  update,
  set,
  connectDatabaseEmulator,
} from "firebase/database";
import { v4 as uuidv4 } from "uuid";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJQBEUSufcHGOa6MRHZst-L4w_ucA7QdQ",
  authDomain: "ofertila-da447.firebaseapp.com",
  projectId: "ofertila-da447",
  storageBucket: "ofertila-da447.appspot.com",
  messagingSenderId: "93388734570",
  appId: "1:93388734570:web:ea21ce877f5663fcdd8a13",
  databaseURL:
    "https://ofertila-da447-default-rtdb.europe-west1.firebasedatabase.app/",
  measurementId: "G-LN6S9GT9RR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
// if (window.location.hostname === "localhost") {
//   // Point to the RTDB emulator running on localhost.
//   connectDatabaseEmulator(database, "localhost", 8080);
// }

const cleanValues = (values) => {
  if (!values) {
    return null;
  }
  Object.keys(values).forEach((key) => {
    if (values[key] === undefined) {
      values[key] = "";
    }
  });
  return values;
};

const PageForm = ({ data, goTo }) => {
  const [leadkey, setLeadKey] = React.useState(null);

  const onFinish = async (values, flowIsDone = false) => {
    console.log("🚀 ~ onFinish ~ values", values);
    const key = leadkey === null ? uuidv4() : leadkey;
    //iterate trough the keys of values and if is undefined set it to empty string

    console.log("🚀 ~ onFinish ~ key", key);
    let opStatus = false;

    const data = cleanValues(values);

    const submitMethod = leadkey === null ? set : update;

    try {
      await submitMethod(ref(database, "leadsLp/" + key), data);

      opStatus = true;
      setLeadKey(flowIsDone ? null : key);
    } catch (e) {
      console.log(e);
      opStatus = false;
    }

    return opStatus;
  };

  const goNext = async (values) => {
    const operationStatus = await onFinish(values);
    if (operationStatus) {
      goTo();
    } else {
      console.log("show error msg");
    }
  };

  const RenderForm = {
    first: <MainForm onFinish={goNext}  data={data} />,
    second: <SecondaryForm onFinish={goNext}  data={data} />,
    last: <DragDropList onFinish={goNext} data={data} />,
  };
  return <div>{RenderForm[data.formName]}</div>;
};

export default PageForm;
