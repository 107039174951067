import React from "react";
import { Carousel, Card, Row, Space, Col, Image } from "antd";

const PageContent = React.forwardRef((props, ref) => {
  const {
    data,
    carouselRef,
    currentSlide,
    beforeChangeSlide,
    isMobileVersion,
  } = props;

  const getBenefitDisplayRules = (index) => {
    if (currentSlide === index) {
      return "block";
    }
    if (isMobileVersion) {
      return "none";
    }
    return "block";
  };

  return (
    <div
      ref={ref}
      className="content-wrapper-inner"

    >
      <Row
        id="caracteristici"
        justify="center"
        align="middle"
        wrap
        gutter={[0, 0]}
      >
        <Col
          sm={24}
          xs={{ span: 24 }}
          md={{ span: 15,offset: 1 }}
          lg={{ span: 15, offset: 1 }}
          xl={{ span: 15, offset: 1 }}
          xxl={{ span: 15, offset: 1 }}
          style={{
            padding: 0,
          }}
          order={1}
        >
          <Space size="small" className="features-wrapper" direction="vertical">
            {data.map((item, index) => {
              return (
                <Card
                  bordered={currentSlide === index + 1}
                  className={
                    currentSlide === index
                      ? "border-primary with-border-radius"
                      : "border-white with-border-radius"
                  }
                  style={{
                    display: getBenefitDisplayRules(index),
                    width: 340,
                    textAlign: "right",
                  }}
                  key={index.toString() + "text"}
                >
                  {item.text}
                </Card>
              );
            })}
          </Space>
        </Col>
        <Col
          xs={24}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
          order={0}
          align={isMobileVersion ? "middle" : "start"}
        >
          <div className="carousel-wrapper">
            <Carousel
              ref={carouselRef}
              beforeChange={beforeChangeSlide}
              autoplay={!isMobileVersion}
              effect={"fade"}
              variableWidth={true}
              vertical={true}
              centerPadding={true}
              dotPosition={"bottom"}
              autoplaySpeed={2000}
            >
              {data.map((item, index) => {
                return (
                  <div
                    style={{ width: "100%" }}
                    key={index.toString() + "image"}
                  >
                    <Image
                      width={isMobileVersion ? 180 : 232}
                      className="image-slider"
                      src={item.image}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </Col>
      </Row>
    </div>
  );
});
export default PageContent;
