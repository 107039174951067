
import React from 'react';
import { Button} from 'antd';
const  CtaButton =   props =>{

     return(
        <div className="cta-btn-wrapper">
        <Button {...props}   className="cta-btn" >
        {props.children}
        </Button>
      </div>
    );
};
export default CtaButton;