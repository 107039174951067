import { SLIDER, FORM, PACKAGES, RESULTS } from "./page.types";
import partnersIllustration from "./assets/images/partners-illustration.svg";
import bigOfertila from "./assets/images/big-ofertila.png";

import Offers from "./assets/images/ShopDetailsScreen-OffersTab.png";
import Loyalty from "./assets/images/ShopDetailsScreen-Loyalty.png";
import PushNotificationsFake from "./assets/images/MockPushNotifications.png";
import AccountScreen from "./assets/images/AccountScreen.png";
import ClaimOffersScreen from "./assets/images/ClaimOffersScreen.png";
import VerifyCustomerAccount from "./assets/images/VerifyCustomerAccount.png";
import SyncDataScreen from "./assets/images/SyncDataScreen.png";
import ADCSelectPublic from "./assets/images/ADC-SelectPublic.png";
import ADCSelectType from "./assets/images/ADC-SelectType.png";
import ADCOfferDetails from "./assets/images/ADC-OfferDetails.png";
import ADCPush from "./assets/images/ADC-Push.png";
import ShopsScreen from "./assets/images/ShopsScreen.png";

const PAGES_DATA = [
  {
    path: "/",
    title:
      "Vinzi produse sau servicii într-un magazin deschis intr-o locație fizica?",
    ctaText: "Află cum îl folosesc clienții",
    name: "first page",
    pageType: SLIDER,
    showCta: true,
    content: [
      {
        image: Offers,
        text: "Crești vânzările prin expunerea ofertelor din magazinul tău fizic în aplicație ",
      },
      {
        text: "Ai clienți fideli, prin oferte țintite în funcție de preferințele de cumpărare și date demografice ",
        image: Loyalty,
      },
      {
        image: PushNotificationsFake,
        text: "Nu pierzi legătură cu clienții după ce au ieșit din magazin, pentru ca acum le poți transmite comunicare direct cu promoții și anunțuri informative",
      },
    ],
    descriptionP1:
      "Descoperă cum poți crește vânzările in magazinul tău fizic cu “Ofertila”. ",
    descriptionP2:
      "Acum clienții pot scana card-ul și în magazinul tău, la fel ca în marile lanțuri de supermarketuri. ",
    descriptionP3:
      "Și tu poți oferi promoții pe baza de puncte ca sa cumpere mai mult si sa te viziteze din nou. ",
    descriptionP4: "Și din nou. Și costa mult mai putin decat te astepti.",
    introImage: partnersIllustration,
  },

  {
    path: "/cum-functioneaza",
    title: "Cum îl folosesc clienții",
    pageType: SLIDER,
    showCta: true,
    ctaText: "Cum îl folosesc antreprenorii?",
    content: [
      {
        image: Offers,
        text: "1.Clientul selectează oferta(ele) de care vrea să profite în magazinul tău ",
      },
      {
        text: "2.Vânzătorul scanează card-ul/codul QR generat de aplicație pe ecranul telefonului clientului sau solicita codul scurt generat de aplicație ",
        image: AccountScreen,
      },
      {
        image: ClaimOffersScreen,
        text: "3. Gata! O relatie lunga de durata a fost deschisa între tine și client ",
      },
    ],
    descriptionP1: "",
    descriptionP2: "",
    introImage: null,
  },
  {
    path: "/cum-folosesc-vanzatorii",
    title: "Cum îl folosesc antreprenorii?",
    ctaText: "Vezi cât de ușor configurezi oferta",
    pageType: SLIDER,
    showCta: true,
    content: [
      {
        image: AccountScreen,
        text: "1. După ce vânzătorul scanează codul QR sau introduce codul comunicat de client, pe ecranul aplicației îi va apare codul necesar pentru aplicarea promoției prin casă de marcat",
      },
      {
        text: "2.Operatorul introduce în aplicație numărul bonului fiscal emis de casă de marcat",
        image: VerifyCustomerAccount,
      },
      {
        image: SyncDataScreen,
        text: "3. Prin sincronizarea datelor din casă de marcat cu cele din aplicația “Ofertila” va puteți cunoaște clienții și nevoile lor prin identificarea  segmentele  de public în funcție de datele demografice, produsele cumpărate, tipul și valoarea lor",
      },
      {
        image: ADCSelectPublic,
        text: "4. Folosiți segmentele de public rezultate pentru transmiterea de oferte personalizate.",
      },
    ],
    descriptionP1: "",
    descriptionP3: "",
    descriptionP2: "",
    introImage: null,
  },
  {
    path: "/configurezi-usor-oferta",
    title: "Cât de ușor configurezi oferta…",
    name: "second page",
    ctaText: "Știi de ce îl folosesc antreprenorii?",
    pageType: SLIDER,
    showCta: true,
    content: [
      {
        image: ADCSelectType,
        text: "1. Selectezi tipul de comunicare dorit: anunț informativ (ne-am mutat sediul, oferta promoțională sau program fidelizare",
      },
      {
        text: "2. Introduci titlul ofertei, durată de desfășurare, imaginea de copertă a promotiei, condiții și reguli",
        image: ADCOfferDetails,
      },
      {
        image: ADCSelectPublic,
        text: "3. Selectezi segmentele de public pe care le țintești cu oferta în funcție de datele demografice și informații agregate privind cumpărăturile efectuate în trecut",
      },
      {
        image: ADCPush,
        text: "4. Poți alege să trimiți notificări push pe telefon cu oferta ta clienților existenți sau celor care au marcat magazinul tău favorit",
      },
    ],
    descriptionP1: "",
    descriptionP2: "",
    descriptionP3: "",
    introImage: null,
  },
  {
    path: "/beneficii",
    title: "Beneficii pentru afacerea ta",
    pageType: SLIDER,
    showCta: true,
    name: "second page",
    ctaText: "Cât costă",
    content: [
      {
        image: ShopsScreen,
        text: "Atragi clienți noi din cartierul sau orașul în care activezi prin ofertele publicate în aplicație",
      },
      {
        text: "Îți fidelizezi clienții existenți prin programe de fidelizare dinamice si oferte transmise țintit, în funcție de preferințe, produse  cumpărate în trecut și datele demografice  ale utilizatorului",
        image: Loyalty,
      },
      {
        image: PushNotificationsFake,
        text: "Îți păstrezi clientela chiar dacă trebuie să îți muți punctul de vânzare sau să îți schimbi programul din cauza unui  eveniment neprevăzut",
      },
    ],
    descriptionP1: "",
    descriptionP2: "",
    descriptionP3: "",
    introImage: null,
  },
  {
    path: "/abonamente",
    title: "Abonamente",
    showCta: false,
    pageType: PACKAGES,
    ctaText: "CERE OFERTA",
    content: [
      {
        name: "Amethyst",
        noClients: 100,
        noTransactions: 300,
        price: 12.5,
        currency: "EURO",
      },
      {
        name: "Emerald",
        noClients: 400,
        noTransactions: 1200,
        price: 50,
        currency: "EURO",
      },
      {
        name: "Diamond",
        noClients: 1500,
        noTransactions: 4500,
        price: 262.5,
        currency: "EURO",
      },
    ],
    descriptionP1: "",
    descriptionP2: "",
    descriptionP3: "",
    introImage: null,
  },
  {
    path: "/cere-oferta",
    title: "Cere oferta",
    showCta: false,
    pageType: FORM,
    ctaText: "TRIMITE",
    content: null,
    descriptionP1:
      "Imediat ce lansăm soluția în regiunea ta, te anunțăm la adresa de email pe care te rugăm  să o completezi în formularul de mai jos. ",
    descriptionP2:
      "Completarea formularului de mai sus nu presupune nici un fel de obligatie contractuala din partea dumneavoastra sau/si a firmei pe care o reprezentati sau/si a companiei care opereaza “Ofertila”.",
    introImage: null,
    descriptionP3: "",
    formName: "first",
  },
  {
    path: "/lista-asteptare",
    title: "Lista de așteptare",
    showCta: false,
    pageType: FORM,
    ctaText: "TRIMITE",
    content: null,
    descriptionP1:
      "Mulțumim! Te vom contacta în curând. Datele completate anterior au ajuns la noi! Pentru a ne pregăti mai temeinic înainte de a va contacta ne-ar ajută să ne răspundeți și la următoarele întrebări ",
    descriptionP2: "",
    introImage: null,
    formName: "second",
  },
  {
    path: "/top-beneficii",
    title: "Lista de așteptare",
    showCta: false,
    pageType: FORM,
    ctaText: "TRIMITE",
    content: [
      {
        importanta: "1",
        id: "fidelizare",
        caracteristica: "Fidelizarea clienților existenți",
      },
      {
        importanta: "2",
        id: "vanzari",
        caracteristica: "Creșterea vânzărilor de la clienți noi",
      },
      {
        importanta: "3",
        id: "mesaje",
        caracteristica:
          "Transmiterea rapida de mesaje importante pentru business clienților existenți",
      },
    ],
    descriptionP1:
      "Mulțumim! Va rugăm să ordonați beneficiile serviciului nostru în funcție de valoarea adăugată pe care considerați că o poate aduce afacerii dvs. Astfel, vom putea să vă oferim mai multe detalii relevante ulterior. Aceasta a fost ultima întrebare.",
    descriptionP2: "",
    descriptionP3: "",
    introImage: null,
    formName: "last",
  },
  {
    path: "/multumim",
    title: "Mulțumim!",
    showCta: false,
    pageType: RESULTS,
    ctaText: false,
    content: null,
    descriptionP1:
      "“Te vom contacta în curând prin email sau telefon pentru a află cum îți poți aduce afacerea în aplicația “Ofertila’.”",
    descriptionP2: "echipa “Ofertila”",
    descriptionP3: "",
    introImage: bigOfertila,
  }

];

export default PAGES_DATA;
