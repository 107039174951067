import React from "react";
import { Table } from "antd";
import ReactDragListView from "react-drag-listview";
import CtaButton from "../../components/CtaButton";

const columns = [
  {
    title: "",
    dataIndex: "importanta",
    width: 10,
    render: (text, record, index) => (
      <a className="drag-handle" href="/#">
        {text} {") "}
      </a>
    ),
  },
  {
    title: "Caracteristica",
    dataIndex: "caracteristica",
    render: (text, record, index) => (
      <a className="drag-handle" href="/#">
        {text}
      </a>
    ),
  },
  {
    title: "Ordoneaza",
    key: "ordoneaza",
    render: (text, record, index) => (
      <a className="drag-handle" href="/#">
        Trage
      </a>
    ),
  },

  /**
   * @param {Function} onFinish - callback function to be called when the form is submitted
   * @param {Object} data - the data object that contains the form data
   */
];
const DragDropList = ({ onFinish, data }) => {
  const [options, setData] = React.useState(data.content);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const dataCopy = [...options];
      const item = dataCopy.splice(fromIndex, 1)[0];
      dataCopy.splice(toIndex, 0, item);

      const reordered = dataCopy.map((item, index) => {
        item.importanta = index + 1;
        return item;
      });
      setData(reordered);
    },
    handleSelector: "a",
  };

  const onClick = (e) => {
    let resultOptions = {};

    options.forEach((item, index) => {
      resultOptions[index + 1] = item.id;
    });
    onFinish({ options: resultOptions }, true);
    resultOptions = {};
  };
  return (
    <div style={{ margin: 20 }}>
      <ReactDragListView {...dragProps}>
        <Table columns={columns} pagination={false} dataSource={options} />
      </ReactDragListView>
      <CtaButton style={{ marginTop: 30 }} onClick={onClick} type="primary">
        {data.ctaText}
      </CtaButton>
    </div>
  );
};

export default DragDropList;
