import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import ContentWrapper from "./ContentWrapper";
import logo from "./logo.png";
import "./App.less";
import PAGES_DATA from "./content.constants";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import ReactGA from "react-ga4";
ReactGA.initialize("G-CH01JZRP0L");

function AnimatedSwitch() {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
    console.log("🚀 ~ file: App.js:26 ~ useEffect ~ location:", location);
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

  }, [location, displayLocation]);

  const [currentPage, setCurrentPage] = React.useState(0);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes>
        {PAGES_DATA.map((page) => {
          const { path } = page;
          return (
            <Route
              key={path}
              path={path}
              element={
                <ContentWrapper
                  data={page}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              }
            />
          );
        })}
        <Route key="Terms" path="termeni-si-conditii" element={<Terms />} />
        <Route
          key="policy"
          path="politica-de-confidentialitate"
          element={<Policy />}
        />
      </Routes>
    </div>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <div className={`App`}>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>

              <a title="Catre prima pagina" href="/">

              <img alt="logo Ofertila" width="90" src={logo} />
              </a>

          </div>
        </div>

        <AnimatedSwitch />
      </div>
    </BrowserRouter>
  );
}
